/* Landscape iPad Pro*/

@media only screen and (min-device-width: 834px) and (max-device-width: 1194px) and (orientation: landscape) {
    .right-container {
        width: 95%;
    }
    .header .container-left {
        width: 30%;
    }
    /* .header {
    position: absolute;
    top: 0px;
    width: 1124px;
    height: 78px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
  } */
    .header .div-search {
        left: 1000px;
    }
    .header .div-notification {
        left: 1050px;
    }
    .container-search {
        width: 1124px;
    }
    .title-row {
        width: 1124px;
    }
    .title-row .title {
        margin: 33px 704px 257px 235px;
    }
    .title-row .description {
        margin: 66px 663px 150px 235px;
    }
    /* .search-input {
    width: 1060px;
  } */
    .grid-row {
        width: 1124px;
    }
    .row-position {
        top: 575px;
        left: 75px;
    }
    .overflow {
        height: 195px;
        margin-top: 15px;
        overflow: auto;
    }
    .grid-title {
        text-align: left;
        font: normal normal bold 14px Montserrat;
        letter-spacing: 0px;
        color: #2C728A;
    }
    .text-bold {
        text-align: left;
        font: normal normal bold 12px SourceSansPro;
        letter-spacing: 0px;
        color: #414141;
    }
    .text-normal {
        text-align: left;
        font: normal normal normal 12px SourceSansPro;
        letter-spacing: 0px;
        color: #414141;
    }
    /* .partner-grid-box {
    width: 470px;
    height: 53px;
    background: #EFEFEF 0% 0% no-repeat padding-box;
    border: 1px solid #C4C4C44D;
    border-radius: 5px;
    margin-top: 11px;
    padding: 10px;
    line-height: 0px;
  } */
    /* .rep-grid-box {
    width: 470px;
    border: 1px solid #C4C4C44D;
    border-radius: 5px;
    margin-top: 11px;
    padding: 10px;
    line-height: 0px;
    display: inline-flex;
  } */
    .row-full-width {
        top: 909px;
        left: 30px;
        width: 1055px;
        height: 230px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        position: absolute;
        padding: 10px;
    }
    .div-img {
        margin: 30px 10px 20px 0px;
    }
    .div-img .title {
        /* top: 1429px; */
        left: 205px;
        width: 182px;
        height: 18px;
        text-align: left;
        font: normal normal bold 14px Montserrat;
        letter-spacing: 0px;
        color: #414141;
        position: absolute;
    }
    .div-img .description {
        top: 90px;
        left: 205px;
        width: 226px;
        height: 90px;
        text-align: left;
        font: normal normal normal 14px SourceSansPro;
        letter-spacing: 0px;
        color: #414141;
        position: absolute;
    }
    .div-img-right {
        position: absolute;
        top: 59px;
        left: 560px;
        width: 142px;
        height: 122px;
    }
    .div-img-right .title {
        top: 0px;
        left: 205px;
        width: 182px;
        height: 18px;
        text-align: left;
        font: normal normal bold 14px Montserrat;
        letter-spacing: 0px;
        color: #414141;
        position: absolute;
    }
    .div-img-right .description {
        top: 31px;
        left: 205px;
        width: 226px;
        height: 90px;
        text-align: left;
        font: normal normal normal 14px SourceSansPro;
        letter-spacing: 0px;
        color: #414141;
        position: absolute;
    }
    .div-sales .title {
        text-align: left;
        font: normal normal bold 14px SourceSansPro;
        letter-spacing: 0px;
        color: #2C728A;
    }
    .div-sales .sub-title {
        text-align: left;
        font: normal normal normal 11px SourceSansPro;
        letter-spacing: 0px;
        color: #414141;
    }
    .table-row-head {
        text-align: left;
        font: normal normal normal 12px SourceSansPro;
        letter-spacing: 0px;
        color: #747476;
    }
    .table-row-data {
        text-align: left;
        font: normal normal 600 12px SourceSansPro;
        letter-spacing: 0px;
        color: #414141;
    }
    .chevleft {
        /* position: absolute;
    left: 520px; */
        width: 4px;
        height: 7px;
    }
    .pitch-hr {
        position: absolute;
        width: 99%;
        top: 80px;
    }
    .rep-grid-box .initials {
        width: 25px;
        height: 25px;
    }
    .rep-grid-box .initials .text {
        /* top: 891px;
    left: 128px;
    width: 15px;
    height: 18px; */
        text-align: left;
        font: normal normal normal 12px SourceSansPro;
        letter-spacing: 0px;
        color: #FFFFFF;
    }
    .rep-grid-box .title {
        font: normal normal 600 10px Montserrat;
    }
    .rep-grid-box .sub-title {
        text-align: left;
        font: normal normal normal 10px SourceSansPro;
        letter-spacing: 0px;
        color: #A7A9AB;
    }
    .rep-grid-box .div-content {
        margin: 0px 10px 0px 10px;
    }
    .width-20 {
        width: 20% !important;
    }
    .width-33 {
        width: 33% !important;
    }
    .width-25 {
        width: 25% !important;
    }
    .pop-title {
        font: normal normal bold 14px Montserrat;
    }
    .pop-key {
        font: normal normal normal 12px SourceSansPro;
    }
    .pop-box {
        height: 85px;
        padding: 5px;
    }
    .selection-box {
        width: 78.5%;
    }
    .modal-dialog {
        max-width: 85% !important;
    }
}


/* Portrait iPad Pro*/

@media only screen and (min-device-width: 767px) and (max-device-width: 1194px) and (orientation: portrait) {
    .margin-left {
        margin-left: 0px;
    }
    .pop-box {
        height: 85px;
        padding: 5px;
    }
    .pop-title {
        font: normal normal bold 14px Montserrat;
    }
    .pop-key {
        font: normal normal normal 12px SourceSansPro;
    }
    .main {
        margin-left: 0px;
    }
    .pd-r-none {
        padding-right: 0px !important;
    }
    .pl-135 {
        padding-left: 58px !important;
    }
    .mt-sm-30 {
        margin-top: 30px !important;
    }
    .container-fluid {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .left-container {
        display: none;
    }
    .sidenav {
        display: none;
    }
    .container-search {
        left: 0px;
        width: 100%;
    }
    .grid-row {
        left: 0px;
    }
    .title-row {
        left: 0px;
        width: 100%;
        display: inline-flex;
    }
    .title-row img {
        margin: 30px;
    }
    /* .header .title-left {
    margin-left: 20px;
  } */
    .header .title-center {
        top: 35px;
    }
    .bottom-container {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 3px 4px #00000029;
        position: fixed;
        bottom: 0px;
        width: 100%;
        height: 80px;
        display: block;
    }
    .bottom-container ul {
        text-align: center;
        justify-content: center;
    }
    .bottom-container .user-initials {
        width: 45px;
        height: 45px;
        background: #2C728A 0% 0% no-repeat padding-box;
        border-radius: 50%;
        justify-content: center;
        display: flex;
        align-items: center;
    }
    .bottom-container .user-initials label {
        color: #fff;
        font-weight: 900;
    }
    .bottom-container .menu-items ul {
        display: inline-flex;
        list-style-type: none;
    }
    .bottom-container .menu-items ul li {
        text-align: center;
        margin-left: 30px;
        margin-right: 30px;
    }
    .right-container {
        left: 0px;
        width: 100%;
    }
    .header {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 2px 3px 6px #00000029;
    }
    .header .container-left {
        width: 25%;
        left: 0px;
    }
    .header .container-center {
        top: 35px;
    }
    .grid-card {
        left: 0px;
    }
    /* .card {
    width: 30%;
    margin: 0px 10px 22px 30px;
  } */
    .hr {
        display: none;
    }
    .hide {
        display: none;
    }
    .card-img {
        width: 27.5vw;
    }
    .selection-box {
        bottom: 100px;
        height: 75px;
        width: 83.5%;
    }
    .img-globe-p {
        margin-right: 10px;
        display: inline-flex;
    }
    .catalogue-title {
        left: 30px;
    }
    .title-row .description {
        margin: 0px;
        left: 230px;
        top: 55px;
    }
    .title-row .title {
        margin: 0px;
        left: 230px;
        top: 30px;
    }
    .title-row .ent-coloum {
        left: 530px;
        top: 55px;
        width: 25%;
    }
    .customer-count {
        left: 530px;
    }
    /* .modal-dialog {
        max-width: 85% !important;
    } */
    .w-ip-53 {
        width: 53% !important;
    }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
    .mt-sm-30 {
        margin-top: 0px !important;
    }
    .grid-col-right {
        padding: 20px;
    }
    .nb-t {
        font: normal normal 400 10px Montserrat;
    }
    .nb-des {
        font: normal normal 10px SourceSansPro;
    }
}