@media only screen and (max-device-width: 480px) {
    body {
        display: none;
    }
    .m-hide {
        display: none !important;
    }
    .main {
        margin-left: 0px;
        padding: 0px;
    }
    .search-input {
        font: normal normal normal 8px SourceSansPro;
    }
    .img-globe-p {
        display: inline;
    }
}