.left-container {
    top: 0px;
    left: 0px;
    width: 70px;
    position: fixed;
    height: -moz-calc(100%);
    height: -webkit-calc(100%);
    height: calc(100%);
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 2px 3px 6px #00000029;
    z-index: 1;
}

.left-container .globe {
    width: 100%;
    height: 80px;
    text-align: center;
    padding-top: 10px;
}

.font-14 {
    font: normal normal normal 14px SourceSansPro;
}

.font-12 {
    font-size: 12px !important;
}

.tab-font {
    font: normal normal 600 16px Montserrat;
    letter-spacing: 0px;
    color: #2C728A !important;
}

.inner-tab-font {
    font: normal normal 600 14px Montserrat;
    letter-spacing: 0px;
    color: #2C728A !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    border-color: #fff #fff #fff !important;
    border-bottom: 5px solid #2C728A !important;
}

.hide {
    display: none !important;
}

.show-flex {
    display: inline-flex !important;
}

.left-container ul {
    text-align: center;
}

.left-container .user-initials {
    position: absolute;
    top: calc(100% - 50px);
    left: 19px;
    width: 32px;
    height: 32px;
    background: #2C728A 0% 0% no-repeat padding-box;
    border-radius: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.logout {
    font: normal normal normal 16px SourceSansPro;
    letter-spacing: 0px;
    color: #2C728A;
    cursor: pointer;
}

.bottom-container {
    display: none;
}

.left-container .menu-items {
    position: absolute;
    top: 91px;
    left: 19px;
    width: 30px;
    height: 240px;
}

.left-container .menu-items ul {
    padding: 0;
    margin: 0;
}

.left-container .menu-items ul li {
    list-style-type: none;
    margin-bottom: 30px;
    line-height: 0;
    text-align: center;
}

.left-container .menu-items ul li label {
    text-align: left;
    font: normal normal normal 11px SourceSansPro;
    letter-spacing: 0px;
    color: #2C728A;
}

.left-container .menu-items .calendar-text {
    top: 119px;
    left: 24px;
    width: 24px;
    height: 14px;
}

.left-container .menu-items .pitchetmp-text {
    top: 257px;
    left: 9px;
    width: 54px;
    height: 14px;
}

.left-container .user-initials label {
    top: 776px;
    left: 25px;
    /* width: 18px; */
    height: 18px;
    text-align: left;
    font: normal normal 600 14px SourceSansPro;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.right-container {
    position: absolute;
    top: 0px;
    left: 75px;
    width: 95%;
}

.header {
    height: 78px;
    background: #F5F5F5 0% 0% no-repeat padding-box;
    text-align: center;
    padding: 25px;
}

.header .container-left {
    position: absolute;
    top: 20px;
    width: 22%;
    height: 24px;
    cursor: pointer;
}

.header .container-center {
    position: absolute;
    top: 25px;
    left: 45%;
    text-align: center;
    letter-spacing: 0px;
    color: #414141;
}

.header .title-left {
    top: 0px;
    left: 0px;
    text-align: left;
    font: normal normal bold 20px Montserrat;
    letter-spacing: 0px;
    color: #2C728A;
}

.header .title-center {
    font: normal normal normal 20px Montserrat;
    color: #414141;
}

.header .div-search {
    position: absolute;
    top: 27px;
    right: 100px;
    width: 35px;
    height: 34px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
}

.header .div-notification {
    position: absolute;
    top: 27px;
    right: 40px;
    width: 35px;
    height: 34px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
}

.header .icon-right {
    top: 25%;
    left: 25%;
    position: absolute;
}

.container-search {
    width: 100%;
    padding: 30px 30px 15px 30px;
    background: #ECECED 0% 0% no-repeat padding-box;
}

.margin-left {
    margin-left: 71px;
}

.cursor {
    cursor: pointer !important;
}

.pop-border-radius {
    border-radius: 11px;
}

.pop-title {
    text-align: left;
    font: normal normal bold 16px Montserrat;
    letter-spacing: 0px;
    color: #414141;
}

.pop-key {
    text-align: left;
    font: normal normal normal 12px SourceSansPro;
    letter-spacing: 0px;
    color: #747476;
}

.pop-value {
    text-align: left;
    font: normal normal 600 14px SourceSansPro;
    letter-spacing: 0px;
    color: #2C728A !important;
}

.modal-body .pop-value {
    font: normal normal 600 12px SourceSansPro;
}

.pop-nfo {
    background: #2CB5E51C 0% 0% no-repeat padding-box;
    border-radius: 9px;
    padding: 5px 10px;
}

.pop-initials {
    background: #F6F6F6 0% 0% no-repeat padding-box;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    text-align: center;
}

.pop-box {
    background: #EFEFEF8C 0% 0% no-repeat padding-box;
    height: 90px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 8px;
}

.pop-pill {
    background: #256F922E 0% 0% no-repeat padding-box;
    border-radius: 12px;
    padding: 2px 10px;
}

.drawer-box {
    position: absolute;
    top: 0;
    right: 0;
    width: 20%;
    background: #FFFFFF;
    padding: 10px;
    height: 100%;
    overflow: auto;
}

.leaflet-popup-content {
    width: 100% !important;
}

.leaflet-popup-close-button {
    display: none !important;
}

.white-box {
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    display: flex;
}

.bg-fade {
    background: #EFEFEF 0% 0% no-repeat padding-box;
}

.border-radius {
    border-radius: 10px;
}

.z-index {
    z-index: 1;
}

.pt-15 {
    padding-top: 15px;
}

.pt-0 {
    padding-top: 0px;
}

.pd-tb {
    padding: 10px 0px 10px 0px;
}

.pr-20 {
    padding-right: 20px;
}

.pd-35 {
    padding: 35px;
}

.pd-0 {
    padding: 0px !important;
}

.pd-25 {
    padding: 25px;
}

.pd-20 {
    padding: 20px;
}

.pd-15 {
    padding: 15px;
}

.pl-30 {
    padding-left: 30px !important;
}

.pl-135 {
    padding-left: 135px !important;
}

.pb-100 {
    padding-bottom: 100px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.bblr {
    border-bottom-left-radius: 8px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pd-white-box {
    padding: 30px 49px !important;
}

.pd-lr {
    padding: 0px 49px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.mt-n {
    margin-top: -10px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mb-2 {
    margin-bottom: 2px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mt-25 {
    margin-top: 25px !important;
}

.ml-10 {
    margin-left: 10px;
}

.ml-30 {
    margin-left: 30px !important;
}

.mr-10 {
    margin-right: 10px !important;
}

.mr-15 {
    margin-right: 15px !important;
}

.mr-30 {
    margin-right: 30px !important;
}

.br {
    border-right: 1px solid #dee2e6;
}

.w-97 {
    width: 97% !important;
}

.w-90 {
    width: 90%;
}

.w-80 {
    width: 80%;
}

.w-85 {
    width: 85% !important;
}

.w-8 {
    width: 8%;
}

.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}

.w-25 {
    width: 25%;
}

.w-30 {
    width: 30%;
}

.w-50 {
    width: 50%;
}

.int-count {
    padding: 0px 5px 1px 5px;
    border-radius: 3px;
    font: normal normal 600 16px SourceSansPro;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.popup-placment {
    left: -25vh !important;
    width: 50vh !important;
}

.mapView .info {
    font: normal normal bold 12px Montserrat;
    color: #2c728a;
}

.mapView .info-address {
    font: normal normal normal 12px SourceSansPro;
}

.tag-heading {
    font: normal normal bold 16px Montserrat;
}

.ant-list-item-meta-title {
    font: normal normal bold 14px Montserrat;
    color: #006858 !important;
}

.ant-list-item-meta-description {
    font: normal normal normal 12px SourceSansPro;
    color: #666 !important;
    ;
}

.ant-list-split .ant-list-item {
    border-bottom: 1px solid #ddd !important;
}

.font-13 {
    font: normal normal normal 13px SourceSansPro;
}

.button-font {
    font: normal normal normal 12px SourceSansPro;
    color: #5F5F5F;
}

.red {
    background: #AA111C 0% 0% no-repeat padding-box;
}

.yellow {
    background: #D9821F 0% 0% no-repeat padding-box;
}

.blue {
    background: #2C728A 0% 0% no-repeat padding-box;
}

.templateSelect {
    background: #E3EFEE 0% 0% no-repeat padding-box;
    border: 1px solid #2C728A;
    border-radius: 9px;
}

.visibility {
    visibility: hidden;
}

.w-99 {
    width: 99% !important;
}

.w-20 {
    width: 20% !important;
}

.w-60 {
    width: 60% !important;
}

.w-30 {
    width: 30% !important;
}

.pa {
    position: absolute;
}

.w-20 {
    width: 20% !important;
}

.back-trans {
    background: transparent;
}

.market-search {
    height: 100px;
    background: #E3EFEE 0% 0% no-repeat padding-box;
    border: 1px solid #2C728A7D;
}

.market-search-label {
    text-align: left;
    font: normal normal 600 14px SourceSansPro;
    letter-spacing: 0px;
    color: #5F5F5F;
}

.w-45 {
    width: 45% !important;
}

.card-body {
    padding: 0px !important;
    background: #EFEFEF !important;
}

.card {
    border: 1px solid #c4c4c4c7 !important;
    border-radius: 0px 0px 0px 8px !important;
}

.copy {
    padding: 10px;
    /* width: 33%; */
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 6px;
}

.title-fade {
    color: #d9d9d9
}

.color-blue {
    color: #2C728A !important;
}

.bold {
    font-weight: 600 !important;
}

.underline {
    text-decoration: underline;
}

.contact-card {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 9px;
    height: 177px;
}

.contact-text {
    text-align: center;
    font: normal normal 600 16px Montserrat;
    letter-spacing: 0px;
    color: #000000;
}

.slick-prev:before,
.slick-next:before {
    color: #000000;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.title-row img {
    position: absolute;
    margin: 33px 919px 98px 30px;
    width: 175px;
    height: 175px;
}

.white-box .title {
    text-align: left;
    font: normal normal bold 18px Montserrat;
    letter-spacing: 0px;
    color: #414141;
}

.white-box .description {
    /* position: absolute; */
    text-align: left;
    font: normal normal normal 14px SourceSansPro;
    letter-spacing: 0px;
    color: #414141;
}

.hr {
    width: 100%;
}

.bg-white {
    background: #FFFFFF 0% 0% no-repeat padding-box;
}

.table>:not(:first-child) {
    border-top: 1.5px solid #187770 !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    background: #effffe 0% 0% no-repeat padding-box !important;
}

.table thead {
    font: normal normal bold 12px Montserrat;
    color: #414141;
}

.title-row .hr {
    width: 95%;
    height: 0px;
    border: 1px solid #707070;
    opacity: 0.24;
    margin: 232px 29px 79px 30px;
}

.title-row .ent-coloum {
    position: absolute;
    top: 65px;
    left: 658px;
    width: 408px;
    height: 18px;
    text-align: left;
    font: normal normal normal 14px SourceSansPro;
    letter-spacing: 0px;
    line-height: 19px;
    color: #414141;
}

.customer-count {
    position: absolute;
    top: 145px;
    left: 658px;
    width: 221px;
    height: 19px;
    text-align: left;
    font: normal normal bold 16px Montserrat;
    letter-spacing: 0px;
    color: #414141;
}

.customer-count span {
    display: block;
    text-align: left;
    font: normal normal normal 14px SourceSansPro;
    letter-spacing: 0px;
    color: #414141;
}

.img-globe-p {
    display: none;
}

.customer-tab {
    text-align: left;
    font: normal normal 600 18px Montserrat;
    letter-spacing: 0px;
    color: #2C728A;
}

.customer-tab label {
    margin-left: 51px;
    cursor: pointer;
}

.customer-tab span {
    position: absolute;
    top: 39px;
    left: -15px;
    width: 100px;
    height: 5px;
    background: #1892BF 0% 0% no-repeat padding-box;
}

.cust-title-row {
    font: normal normal bold 20px Montserrat;
    letter-spacing: 0px;
}

.cust-title-row span {
    display: block;
    font: normal normal normal 14px SourceSansPro;
    letter-spacing: 0px;
    color: #414141;
}

.cust-title-row .container {
    position: absolute;
    top: 330px;
    right: 40px;
}

.print-btn button {
    top: 350px;
    left: 579px;
    height: 31px;
    background: #2C728A 0% 0% no-repeat padding-box;
    color: #FFFFFF;
    border-radius: 3px;
    margin-left: 15px;
    opacity: 1;
}

.print-cust {
    width: 237px;
}

.prep-work {
    width: 129px;
}

.selected {
    border-bottom: 5px solid;
    padding-bottom: 18px;
}

.cust-title-row .response {
    width: 175px;
}

.grid-row {
    width: 95%;
    position: absolute;
    top: 575px;
    left: 75px;
}

.grid-card {
    width: 95%;
    position: absolute;
    display: inline-flex;
    top: 150px;
    left: 75px;
}

.container-margin-top {
    margin-top: 25px !important;
}

.search-margin-top {
    margin-top: 110px;
}

.menu-select {
    background: #d3d3d3;
}

.search-title-row {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #D6D6D6;
    border-radius: 7px;
    padding: 20px;
    margin: 0px !important;
    margin-top: 30px !important;
    cursor: pointer;
}

.search-divider {
    margin: 0 0 20px 0 !important;
    color: #ececed !important;
}

.search-margin-left {
    margin-left: 30px;
}

.search-margin-left .title {
    font: normal normal bold 18px Montserrat;
    letter-spacing: 0px;
    color: #414141;
    margin-bottom: 8px;
}

.search-margin-left .description {
    font: normal normal normal 14px SourceSansPro;
    letter-spacing: 0px;
    color: #414141;
}

.scheduler-div {
    background: #ececed;
    width: 50%;
    padding: 1px 5px;
}

.m-top {
    margin-top: 20px;
}

.m-top .description {
    font: normal normal normal 14px SourceSansPro;
    letter-spacing: 0px;
    color: #414141;
}

.search-title {
    font: normal normal bold 14px Montserrat;
    letter-spacing: 0px;
    color: #414141;
}

.input-container {
    position: relative;
    width: 100%;
}

.input-border {
    outline: none !important;
    border: 2px solid #187770 !important;
}

.clear-search {
    width: 12px;
    height: 14px;
    background: #FFFFFF url(../assets/img/search_clear.svg) no-repeat;
    content: ' ';
    position: absolute;
    right: 50px;
    top: 14px;
    cursor: pointer;
}

.icon-search {
    width: 45px;
    height: 39px;
    background: #2C728A url(../assets/img/search_on_focus.svg) no-repeat 50% 50% padding-box;
    content: ' ';
    position: absolute;
    right: 0px;
    top: 0px;
    cursor: pointer;
    border-radius: 4px;
}

.search-input {
    width: 100%;
    height: 39px;
    padding: 8px;
    box-shadow: inset 0px 2px 2px #00000029;
    border: 1px solid #7070704F;
    border-radius: 4px;
    font: normal normal normal 14px SourceSansPro;
}

.search-query {
    background: #FFFFFF url(../assets/img/search-icon.svg) no-repeat 99% 50% padding-box;
}

.advance-search-button {
    font: normal normal normal 600 12px SourceSansPro;
    letter-spacing: 0px;
    color: #2C728A;
}

.container-margin-bottom {
    margin-bottom: 85px !important;
}

.border-top {
    border-top: 1px solid #707070;
}

.lable-margin-top {
    margin-top: 15px !important;
}

.card-container {
    width: 22%;
    background: white;
    height: 350px;
    margin: 0px 10px 22px 25px;
}

.card-div {
    margin: 10px 10px 10px 25px;
}

.card-para {
    text-align: left;
    font: normal normal normal 11px SourceSansPro;
    letter-spacing: 0px;
    color: #414141;
    height: 55px;
    overflow: hidden;
}

.card-img {
    height: 205px;
    width: 100%;
    width: 20.5vw;
    margin: 1px;
}

.form-switch {
    padding-left: 0.5em !important;
}

.card-checkbox {
    position: absolute;
    z-index: 1;
    margin: 10px;
    width: 15px;
    height: 15px;
}

.selection-box {
    position: fixed;
    bottom: 15px;
    /* width: window.innerWidth; */
    height: 80px;
    background: #2C728A 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 15px #0000007A;
    border-radius: 15px;
    z-index: 9;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}

.display-none {
    display: none;
}

.display-iflex {
    display: inline-flex !important;
}

.display-inline {
    display: inline !important;
}

.display-block {
    display: block !important;
}

.selection-box .button-container {
    text-align: end;
    margin: 22px;
}

.selection-box .button-container .btn-outline-info {
    color: #FFFFFF;
    border-color: #FFFFFF;
    margin-right: 15px;
}

.selection-box .button-container .btn-light {
    color: #2c728a;
}

.selection-box .user-initials {
    position: absolute;
    top: 25px;
    left: 15px;
    width: 32px;
    height: 32px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.selection-box .user-initials label {
    top: 776px;
    left: 25px;
    /* width: 18px; */
    height: 18px;
    text-align: left;
    font: normal normal 600 14px SourceSansPro;
    letter-spacing: 0px;
    color: #2C728A;
}

.selection-box span {
    position: absolute;
    top: 28px;
    left: 55px;
    text-align: left;
    font: normal normal 600 18px SourceSansPro;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.modal-title {
    text-align: left;
    font: normal normal bold 14px Montserrat;
    letter-spacing: 0px;
    color: #414141;
}


/* .modal-dialog {
    max-width: 60% !important;
} */

.btn-secondary {
    color: #2C728A !important;
    background-color: #fff !important;
    border-color: #2C728A !important;
}

.btn-primary {
    color: #fff !important;
    background-color: #2C728A !important;
    border-color: #2C728A !important;
}

.button-pri {
    color: #fff !important;
    background-color: #2C728A !important;
    border-color: #2C728A !important;
    font: normal normal 600 12px Montserrat;
}

.modal select {
    border: 1px solid #0E5B53;
}

.modal input {
    border: 1px solid #0E5B53;
}

.modal textarea {
    border: 1px solid #0E5B53;
}

.modal .form-label {
    font: normal normal normal 16px SourceSansPro;
    letter-spacing: 0px;
    color: #414141;
}

.block {
    display: block !important;
}

.form-title {
    font-weight: 800 !important;
}

.custme-form-control {
    display: block !important;
    width: 100% !important;
    padding: 0.375rem 0.75rem !important;
    font-size: 1rem !important;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: #212529 !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ced4da !important;
    appearance: none !important;
    border-radius: 0.25rem !important;
    border: 1px solid #0E5B53 !important;
}

.row-dislay {
    display: inline-flex;
    width: 100%;
}

.grid-col-left {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 15px;
}

.grid-col-right {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 13px;
    line-height: 26px;
}

.grid-title {
    text-align: left;
    font: normal normal bold 14px Montserrat;
    letter-spacing: 0px;
    color: #2C728A;
}

.overflow {
    height: 195px;
    margin-top: 15px;
    overflow: auto;
}

.div-sales {
    width: 95%;
    background: #EFEFEF 0% 0% no-repeat padding-box;
    border: 1px solid #C4C4C44D;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 10px;
}

.div-sales .title {
    text-align: left;
    font: normal normal bold 14px SourceSansPro;
    letter-spacing: 0px;
    color: #2C728A;
}

.title {
    text-align: left;
    font: normal normal bold 14px SourceSansPro;
    letter-spacing: 0px;
    color: #000000;
}

.sub-title {
    text-align: left;
    font: normal normal normal 11px SourceSansPro;
    letter-spacing: 0px;
    color: #414141;
}

.div-sales .sub-title {
    text-align: left;
    font: normal normal normal 11px SourceSansPro;
    letter-spacing: 0px;
    color: #414141;
}

.table-row-head {
    text-align: left;
    font: normal normal normal 12px SourceSansPro;
    letter-spacing: 0px;
    color: #747476;
}

.table-row-data {
    text-align: left;
    font: normal normal 600 12px SourceSansPro;
    letter-spacing: 0px;
    color: #414141;
}

.text-bold {
    text-align: left;
    font: normal normal bold 14px SourceSansPro;
    letter-spacing: 0px;
    color: #414141;
}

.text-normal {
    text-align: left;
    font: normal normal normal 14px SourceSansPro;
    letter-spacing: 0px;
    color: #414141;
}

.rep-grid-box {
    width: 100%;
    border: 1px solid #C4C4C44D;
    border-radius: 5px;
    margin-top: 11px;
    padding: 10px;
    line-height: 0px;
    display: inline-flex;
}

.rep-grid-box .initials {
    top: 884px;
    left: 120px;
    width: 32px;
    height: 32px;
    background: #187770 0% 0% no-repeat padding-box;
    border-radius: 50%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.sidenav {
    height: 100%;
    /* Full-height: remove this if you want "auto" height */
    width: 70px;
    /* Set the width of the sidebar */
    position: fixed;
    /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1;
    /* Stay on top */
    top: 0;
    /* Stay at the top */
    left: 0;
    overflow-x: hidden;
    /* Disable horizontal scroll */
    padding-top: 20px;
}


/* Style page content */

.main {
    margin-left: 71px;
    /* Same as the width of the sidebar */
    padding: 0px 10px;
}


/* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
    .sidenav a {
        font-size: 18px;
    }
}

.rep-grid-box .initials .text {
    /* top: 891px;
    left: 128px;
    width: 15px;
    height: 18px; */
    text-align: left;
    font: normal normal normal 14px SourceSansPro;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.rep-grid-box .title {
    /* width: 59px;
    height: 15px; */
    text-align: left;
    font: normal normal 600 12px Montserrat;
    letter-spacing: 0px;
    color: #2C728A;
    /* margin: 5px; */
}

.rep-grid-box .sub-title {
    text-align: left;
    font: normal normal normal 12px SourceSansPro;
    letter-spacing: 0px;
    color: #A7A9AB;
}

.rep-grid-box .div-content {
    margin: 0px 10px 0px 10px;
    width: 25%;
}

.partner-grid-box {
    width: 95%;
    /* height: 53px; */
    background: #EFEFEF 0% 0% no-repeat padding-box;
    border: 1px solid #C4C4C44D;
    border-radius: 5px;
    margin-top: 11px;
    padding: 10px;
    line-height: 0px;
}

.chevRight {
    float: right;
}

.row-full-width {
    top: 909px;
    left: 30px;
    width: 94%;
    height: 230px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    position: absolute;
    padding: 10px;
}

.div-img {
    margin: 30px 10px 20px 0px;
}

.nb-t {
    font: normal normal bold 12px Montserrat;
    letter-spacing: 0px;
    color: #414141;
}

.nb-des {
    font: normal normal normal 400 12px SourceSansPro;
    letter-spacing: 0px;
    color: #414141;
}

.nb-link {
    font: normal normal bold 12px Montserrat;
    letter-spacing: 0px;
    color: #2C728A;
}

.div-img-right {
    position: absolute;
    top: 59px;
    left: 55%;
    width: 142px;
    height: 122px;
}

.div-img-right .title {
    top: 0px;
    left: 205px;
    width: 182px;
    height: 18px;
    text-align: left;
    font: normal normal bold 14px Montserrat;
    letter-spacing: 0px;
    color: #414141;
    position: absolute;
}

.div-img-right .description {
    top: 31px;
    left: 205px;
    width: 226px;
    height: 90px;
    text-align: left;
    font: normal normal normal 14px SourceSansPro;
    letter-spacing: 0px;
    color: #414141;
    position: absolute;
}

.ant-avatar {
    background: #FFFFFF !important;
    border: 2px solid #2C728A;
    width: 20px !important;
    height: 20px !important;
}

.ant-avatar-string {
    font: normal normal 600 13.5px Montserrat !important;
    color: #2C728A;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 2px solid #187770 !important;
    border-radius: 0.3125rem !important;
}

.ant-select-arrow {
    color: #187770 !important;
}

.catalogue-title {
    font: normal normal bold 22px Montserrat;
}

.ant-switch-checked {
    background: #187770 !important;
}

.ant-picker-dropdown {
    z-index: 1060 !important;
}