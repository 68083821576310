html,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
    background: #F5F5F5 0% 0% no-repeat padding-box !important;
    font-family: -apple-system, Montserrat, SourceSansPro;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    text-decoration: none;
    cursor: pointer;
}

input:focus {
    outline: none !important;
    border: 2px solid #187770;
}

.menu-lable {
    font: normal normal normal 11px SourceSansPro;
    letter-spacing: 0px;
    color: #2C728A;
    cursor: pointer;
}

@font-face {
    font-family: Montserrat;
    src: url(../assets/fonts/Montserrat-Regular.ttf);
}

@font-face {
    font-family: SourceSansPro;
    src: url(../assets/fonts/SourceSansPro-Regular.ttf);
}